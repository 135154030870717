import React, { useState } from 'react';

import './MailForm.css';
import Button from '../button/Button';
import useEmailSignup from '../../hooks/useEmailSignup';

export const MailForm: React.FC = () => {
    const [formData, setFormData] = useState({
		email: ''
	});

	const [validationErrors, setValidationErrors] = useState({
		email: ''
	});

	const { emailSignup, loading, error, success } = useEmailSignup();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const validateForm = () => {
		const errors = {
			email: '',
			message: ''
		};

		if (!formData.email)
			errors.email = 'Email is required.';

		setValidationErrors(errors);

		return Object.values(errors).every(err => !err);
	};

	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateForm()) {
			return;
		}

		const signup_success: boolean = await emailSignup(formData.email);

		if (signup_success) {
			// clear form
			setFormData({
				email: ''
			});
		}
	};

    return (
        <div className="mail-form-wrap pt-5 mb-5">
            <div className="container text-center pt-5">
                <h1>Sign up to our newsletter</h1>
                <p>Never miss out on any news & get access to exclusive offers!</p>
                <form onSubmit={handleFormSubmit}>
                    <div className="input-group">
                        <input type="email" 
                            placeholder="Enter your email address"
                            className={`form-control box-3d ${validationErrors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}/>
                    </div>
                    <div className="button-group">
                        <Button class_type="primary" text="Sign up" type="submit" />
                    </div>
                </form>
				{validationErrors.email && <div className="alert alert-danger mt-3">{validationErrors.email}</div>}
				{loading && <div className="text-center"><div className="spinner-border text-primary mt-3" role="status"> <span className="visually-hidden">Loading...</span> </div></div>}
				{success && <div className="alert alert-success mt-3" role="alert">{success}</div>}
				{error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
        </div>
    );
};