import { useState } from "react";
import axios from "axios";

const BASE_API_URL: string = process.env.REACT_APP_API_URL || "https://jkm.solutions";

const useSendEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const sendEmail = async (type: string, email: string, message: string) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(BASE_API_URL + "/v1/email/sendHallwylska", {
        type,
        email,
        message,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
      });

      setLoading(false);

      if (response.status === 200) {
        setSuccess("Email sent successfully.");
        return true;
      } else {
        setError("Failed to send email.");
        return false;
      }
    } catch (err: any) {
      setLoading(false);
      setError("An error occurred whilst sending the email.");
      return false;
    }
  };

  return { sendEmail, loading, error, success };
};

export default useSendEmail;