import React from "react";

import './Footer.css';
import { Link, useLocation } from "react-router-dom";
import { MailForm } from "../mail-form/MailForm";

export const Footer = (): JSX.Element => {

    const location: string = useLocation().pathname;
    
    return (
        <footer className={location === '/menu' ? 'white-bg' : 'beige-bg'}>
            <div className="container">
                { location === '/' &&
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 text-center fly-left">
                        <img src="/img/contact.webp" 
                             alt="Mouth with a speaking bubble"
                             height="100"/>
                        <h5 className="champ-title">Contact</h5>
                        <p className="">
                            Hamngatan 4<br />
                            111 47 Stockholm<br />
                            <a href="mailto:reservations@hallwylska.com">
                                reservations@hallwylska.com
                            </a><br />
                            {/* <a href="tel:+46855807504">
                                +46 8 558 075 04
                            </a> */}
                        </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 text-center fly-right mt-5 mt-md-0">
                        <img src="/img/hours.webp" 
                             alt="Two hands with watches"
                             height="100"/>
                        <h5 className="champ-title">Hours</h5>
                        <p className="">
                            Hours vary depending on Julbord seating period and date. See <Link to="/julbord">Julbord</Link> for more information.
                        </p>
                        {/* <p className="">
                            <strong>We are open for Lunch Saturday (21/9) 11.30-15.00</strong><br />
                        </p>
                        <p className="">
                            Wednesday - Saturday 17:00 - Late <br />
                        </p>
                        <p className="">
                            Season ends w39 (or w40 if weather permits!)<br />
                        </p> */}
                    </div>
                </div>}
                <MailForm />
                <div className="row pt-5 pb-4">
                    <p className="text-center mb-1">
                        <strong>
                            Hallwylska Restaurang | Hamngatan 4 | Stockholm
                        </strong>
                    </p>
                    <p className="text-center copy">
                        Copyright &copy; 2023-2024 Hallwylska Restaurang
                    </p>
                </div>
            </div>      
        </footer>
    )
}