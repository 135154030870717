import React from "react";
import { Helmet } from "react-helmet";

import './About.css';

export const About: React.FC = (): JSX.Element => {
  return (
    <>
        <Helmet>
            <title>About - Hallwylska Restaurang</title>
            <meta name="description" content="Learn about the history and culture behind Hallwylska Restaurang, one of Stockholm's most prestigious dining experiences." />
            <link rel="canonical" href="https://hallwylskarestaurang.com/about" />
            <meta property="og:title" content="About - Hallwylska Restaurang" />
            <meta property="og:description" content="Learn about the history and culture behind Hallwylska Restaurang, one of Stockholm's most prestigious dining experiences." />
            <meta property="og:url" content="https://hallwylskarestaurang.com/about" />
            <meta name="twitter:title" content="About - Hallwylska Restaurang" />
            <meta name="twitter:description" content="Learn about the history and culture behind Hallwylska Restaurang, one of Stockholm's most prestigious dining experiences." />
        </Helmet>
        <section className="about beige-bg pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-lg-8 offset-lg-2 offset-md-1 col-xl-6 offset-xl-3">
                        <h1 className="text-green fly-left">About</h1>
                        <p className="fly-left">
                            In May 2015, the enchanting world of Hallwylska burst forth, unveiling the secrets of Stockholm's most captivating courtyard, hidden within the heart of a museum steeped in history. Our restaurant has not just carved a niche in Stockholm's culinary landscape but has become a beloved gem cherished by locals and visitors alike.
                            <br /><br />
                            The year 2020 marked an extraordinary milestone as we added a breathtaking glass house to our already captivating courtyard. This innovation transformed our venue into a year-round haven, allowing guests to savor the exquisite ambiance of this historical gem, no matter the season.
                            <br /><br />
                            Discover a dining experience that transcends time within the walls of Hallwylska, where history, culture, and gastronomy unite to create a truly unforgettable escape.</p>
                        <img src="/img/about.webp" 
                            alt="Four hands with glasses"
                            className="about-image fly-bottom"/>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};