import React from "react";
import { useNavigate } from "react-router-dom";

import './NotFound.css';
import Button from "../../components/button/Button";

export const NotFound = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <section className="not-found beige-bg pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-lg-8 offset-lg-2 offset-md-1 col-xl-6 offset-xl-3 text-center">
                        <h1 className="text-green fly-left">Page not found</h1>
                        <p className="fly-left">
                            We're sorry, it seems that the page you're looking for doesn't exist.
                        </p>
                        <Button text='Go to Homepage'
                                class_type="primary"
                                onClick={() => navigate('/')}/>
                        <img src="/img/about.webp" 
                             alt="Four hands with glasses"
                             className="not-found-image fly-bottom"/>
                    </div>
                </div>
            </div>
        </section>
    )
}