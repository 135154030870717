import React, { useState } from "react";

import { Helmet } from "react-helmet";

import './Event.css';
import Button from "../../components/button/Button";
import useSendEmail from "../../hooks/useSendEmail";

export const Event: React.FC = (): JSX.Element => {
	const [formData, setFormData] = useState({
		email: '',
		message: ''
	});

	const [validationErrors, setValidationErrors] = useState({
		email: '',
		message: ''
	});

	const { sendEmail, loading, error, success } = useSendEmail();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const validateForm = () => {
		const errors = {
			email: '',
			message: ''
		};

		if (!formData.email)
			errors.email = 'Email is required.';

		if (!formData.message)
			errors.message = 'Message is required.';

		setValidationErrors(errors);

		return Object.values(errors).every(err => !err);
	};

	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateForm()) {
			return;
		}

        const success = await sendEmail("Event", formData.email, formData.message);

		if (success) {
			// clear form
			setFormData({
				email: '',
				message: ''
			});
		}
	};

	return (
		<>
			<Helmet>
                <title>Events - Hallwylska Restaurang</title>
                <meta name="description" content="Plan your perfect event with Hallwylska Restaurang's expert team. From corporate gatherings to family celebrations, we cater to all your needs." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/event" />
                <meta property="og:title" content="Events - Hallwylska Restaurang" />
                <meta property="og:description" content="Plan your perfect event with Hallwylska Restaurang's expert team. From corporate gatherings to family celebrations, we cater to all your needs." />
                <meta property="og:url" content="https://hallwylskarestaurang.com/event" />
                <meta name="twitter:title" content="Events - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Plan your perfect event with Hallwylska Restaurang's expert team. From corporate gatherings to family celebrations, we cater to all your needs." />
            </Helmet>
			<section className="event beige-bg pt-5 pb-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<img src="/img/event1.webp" alt="Hand holding a glass" className="event-image-1" />
						</div>
						<div className="col-lg-6">
							<h1 className="text-green">Event</h1>
							<p>
								Whether you're planning a corporate gathering, a family celebration, or a special get-together with friends, we believe that your event should be a reflection of you. Our team of dedicated event specialists is here to transform your vision into a reality, ensuring that every detail is tailored to perfection.
							</p>
							<p>
								From handpicked menu selections that tantalize your taste buds to meticulously designed décor that mirrors your style, we're committed to crafting a memorable event that leaves a lasting impression on you and your guests.
							</p>
							<p>
								Start curating your event, simply fill out the form below. Share your preferences, ideas, and requirements, and we'll get in touch with you to discuss how we can bring your vision to life.
							</p>

							{/* New Form */}
							<form onSubmit={handleFormSubmit}>
								<div className={`mb-3 ${validationErrors.email ? 'has-validation' : ''}`}>
									<label htmlFor="email" className="form-label">Email</label>
									<input	type="email"
											className={`form-control box-3d ${validationErrors.email ? 'is-invalid' : ''}`}
											id="email"
											name="email"
											placeholder="Email"
											value={formData.email}
											onChange={handleInputChange} />
									{validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
								</div>

								<div className={`mb-3 ${validationErrors.message ? 'has-validation' : ''}`}>
									<label htmlFor="message" className="form-label">Message</label>
									<textarea className={`form-control box-3d ${validationErrors.message ? 'is-invalid' : ''}`}
											id="message"
											placeholder="Type your message here ..."
											rows={4}
											name="message"
											value={formData.message}
											onChange={handleInputChange}></textarea>
									{validationErrors.message && <div className="invalid-feedback">{validationErrors.message}</div>}
								</div>
								<p className="text-center">
									<Button class_type="primary" text="Submit" type="submit" />
								</p>
								{loading && <div className="text-center"><div className="spinner-border text-primary mt-3" role="status"> <span className="visually-hidden">Loading...</span> </div></div>}
								{success && <div className="alert alert-success mt-3" role="alert">{success}</div>}
								{error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
							</form>
						</div>
						<div className="col-lg-3">
							<img src="/img/event2.webp" alt="Two legs with shoes saying Bienvenue" className="event-image-2" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
