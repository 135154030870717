import React from "react";

import { Helmet } from "react-helmet";

import './Team.css';

export const Team: React.FC = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Our Team - Hallwylska Restaurang</title>
                <meta name="description" content="Meet the dedicated team behind Hallwylska Restaurang's exceptional dining experience. Our staff's commitment to excellence ensures a memorable visit." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/team" />
                <meta property="og:title" content="Our Team - Hallwylska Restaurang" />
                <meta property="og:description" content="Meet the dedicated team behind Hallwylska Restaurang's exceptional dining experience. Our staff's commitment to excellence ensures a memorable visit." />
                <meta property="og:url" content="https://hallwylskarestaurang.com/team" />
                <meta name="twitter:title" content="Our Team - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Meet the dedicated team behind Hallwylska Restaurang's exceptional dining experience. Our staff's commitment to excellence ensures a memorable visit." />
            </Helmet>
            <section className="team beige-bg pt-5">
                <div className="container">
                    <div className="member-row">
                        <div className="member fly-left">         
                            <img src="/img/team/julia.JPG"
                                height="400"
                                alt="Julia Blåhaga"/>
                            <p className="mb-0 name"><strong>Julia Blåhaga</strong></p>
                            <p className="mb-0"><em>General Manager</em></p>
                            <p>
                                <a href="mailto:julia@hallwylska.com">
                                    julia@hallwylska.com
                                </a>
                            </p>
                        </div>
                        <div className="member fly-left">         
                            <img src="/img/team/oliver.JPG"
                                height="400"
                                alt="Oliver Sundberg"/>
                            <p className="mb-0 name"><strong>Oliver Sundberg</strong></p>
                            <p className="mb-0"><em>Executive Chef</em></p>
                        </div>
                        <div className="member fly-right">         
                            <img src="/img/team/jacob.jpg"
                                height="400"
                                alt="Leemart Jacob"/>
                            <p className="mb-0 name"><strong>Leemart Jacob</strong></p>
                            <p className="mb-0"><em>Restaurant & Entertainment Manager</em></p>
                        </div>
                    </div>
                    <div className="member-row  fly-bottom">
                        {/* <div className="member">         
                            <img src="/img/team/oliver.JPG"
                                height="400"
                                alt="Oliver Sundberg"/>
                            <p className="mb-0 name"><strong>Oliver Sundberg</strong></p>
                            <p className="mb-0"><em>Executive Chef</em></p>
                        </div> */}
                        {/* <div className="member">         
                            <img src="/img/team/sergio.JPG"
                                height="400"
                                alt="Sergio Pinones"/>
                            <p className="mb-0 name"><strong>Sergio Pinones</strong></p>
                            <p className="mb-0"><em>Head Chef</em></p>
                        </div>
                        <div className="member">         
                            <img src="/img/team/elias.JPG"
                                height="400"
                                alt="Elias Hällestrand"/>
                            <p className="mb-0 name"><strong>Elias Hällestrand</strong></p>
                            <p className="mb-0"><em>Bar Chef</em></p>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}