import { useState } from "react";
import axios from "axios";

const BASE_API_URL: string = process.env.REACT_APP_API_URL || "https://jkm.solutions";

const useEmailSignup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const emailSignup = async (email: string) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`${BASE_API_URL}/v1/mailchimp/hallwylska`, {
        email
      });

      setLoading(false);

      if (response.status === 200) {
        setSuccess(response.data.message);
        return true;
      } else {
        setError("Failed to subscribe to newsletter.");
        return false;
      }
    } catch (error: any) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || "An error occurred while subscribing to the newsletter.";
      setError(errorMessage);
      return false;
    }
  };

  return { emailSignup, loading, error, success };
};

export default useEmailSignup;