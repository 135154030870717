import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import './Calendar.css';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';

const events = [
    // {
    //     title: 'xyz',
    //     date: 'xxx',
    //     time: 'displayed under',
    //     link: 'can be activated',
    // },
    {
        title: 'Nosefin',
        date: '2024-05-16',
    },
    {
        title: 'Layer J',
        date: '2024-05-17',
    },
    {
        title: 'Sia',
        date: '2024-05-18',
    },
    {
        title: 'Laurenoo',
        date: '2024-05-22',
    },
    {
        title: 'Melker Jakobsson',
        date: '2024-05-23',
    },
    {
        title: 'Chicho',
        date: '2024-05-23',
    },
    {
        title: 'Andreas Agurre',
        date: '2024-05-24',
    },
    {
        title: 'Shakarchi',
        date: '2024-05-25',
    },
    {
        title: 'Malin Ädel',
        date: '2024-05-29',
    },
    {
        title: 'Angelo',
        date: '2024-05-30',
    },
    {
        title: 'Waldemar Nordström',
        date: '2024-05-31',
    },
    {
        title: 'Sebzda / Rådfeldt',
        date: '2024-06-01',
    },
    {
        title: 'Chicho',
        date: '2024-06-05',
    },
    {
        title: 'Oliver Storgärds',
        date: '2024-06-06',
    },
    {
        title: 'Calle Lehmann',
        date: '2024-06-07',
    },
    {
        title: 'Sebzda / Rådfeldt',
        date: '2024-06-08',
    },
    {
        title: 'Melker Jacobsson',
        date: '2024-06-12',
    },
    {
        title: 'Sebastian Stubbing',
        date: '2024-06-12',
    },
    {
        title: 'Layer J',
        date: '2024-06-13',
    },
    {
        title: 'Chicho',
        date: '2024-06-14',
    },
    {
        title: 'Samsom ario and friends',
        date: '2024-06-15',
    },
    {
        title: 'Hanî',
        date: '2024-06-19',
    },
    {
        title: 'Chicho',
        date: '2024-06-20',
    },
    {
        title: 'Rådfeldt',
        date: '2024-06-26',
    },
    {
        title: 'Oling',
        date: '2024-06-27',
    },
    {
        title: 'Chicho',
        date: '2024-06-28',
    },
    {
        title: 'Eya Mathilda Neila Bourguiba ',
        date: '2024-06-29',
    },

    // July
    {
        title: "Enzio & Benjamin",
        date: "2024-07-03"
    },
    {
        title: "Ricky & Marko",
        date: "2024-07-04"
    },
    {
        title: "Layer J",
        date: "2024-07-05"
    },
    {
        title: "Sebzda",
        date: "2024-07-06"
    },
    {
        title: "Edvin & Willem",
        date: "2024-07-10"
    },
    {
        title: "Ali Reza",
        date: "2024-07-11"
    },
    {
        title: "Samsom",
        date: "2024-07-12"
    },
    {
        title: "Sia",
        date: "2024-07-13"
    },
    {
        title: "Johanna Lindberg",
        date: "2024-07-17"
    },
    {
        title: "Chicho",
        date: "2024-07-18"
    },
    {
        title: "Layer J",
        date: "2024-07-19"
    },
    {
        title: "Waldemar Nordström",
        date: "2024-07-20"
    },
    {
        title: "Pretty Output",
        date: "2024-07-24"
    },
    {
        title: "Chicho",
        date: "2024-07-25"
    },
    {
        title: "Emma Strandberg & Sebzda",
        date: "2024-07-26"
    },
    {
        title: "Waldemar Nordström",
        date: "2024-07-27"
    },
    {
        title: 'Vlads',
        date: '2024-07-31'
    },

    // August
    {
        title: 'Waldemar Nordström & Martin Bustgaard',
        date: '2024-08-01',
    },
    {
        title: 'Eya Mathilda Neila Bourguiba Pedro',
        date: '2024-08-02',
    },
    {
        title: 'Waldemar Nordström',
        date: '2024-08-03'
    },
    {
        title: 'Josefine Nachtweij',
        date: '2024-08-07',
    },
    {
        title: 'Oliver Storgärds',
        date: '2024-08-08'
    },
    {
        title: 'Sebzda & LayerJ',
        date: '2024-08-09'
    },
    {
        title: 'Chicho',
        date: '2024-08-10'
    },
    {
        title: 'Sia & Johanna',
        date: '2024-08-14'
    },
    {
        title: 'Waldemar Nordström x Tudor',
        date: '2024-08-15'
    },
    {
        title: 'Oliver Storgärds',
        date: '2024-08-16'
    },
    {
        title: 'Layer J',
        date: '2024-08-17'
    },
    {
        title: 'Layer J',
        date: '2024-08-21'
    },
    {
        title: 'Andreas Agurre',
        date: '2024-08-22',
        event: 'Crayfish Party',
    },
    {
        title: 'IMPULS',
        date: '2024-08-23'
    },
    {
        title: 'Shakarachi',
        date: '2024-08-24'
    },
    {
        title: 'Rådfeldt',
        date: '2024-08-28'
    },
    {
        title: 'Marco & Diaco',
        date: '2024-08-29'
    },
    {
        title: 'Discrete',
        date: '2024-08-30'
    },
    {
        title: 'Samsom',
        date: '2024-08-31'
    },
    {
        title: 'Julbordspremiär',
        date: '2024-11-14',
        time: '11.30, 15.00, 19.30',
        link: 'https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0'
    },
    {
        title: 'Julafton',
        date: '2024-12-24',
        time: '11.30, 14.15',
        link: 'https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0'
    },
    {
        title: 'Juldagen',
        date: '2024-12-25',
        time: '11.30, 14.15',
        link: 'https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0'
    }
]

function eventClick(info: any) {
    if (info.event.extendedProps.link) {
        window.open(info.event.extendedProps.link, '_blank');
    }
}

export const Calendar = (): JSX.Element => {
    const calendarRef = useRef<any>(null);
    const navigate = useNavigate();
    const julbordEvents = generateJulbordEvents('2024-11-15', '2024-12-23');
    // eslint-disable-next-line
    const [calendarOptions, setCalendarOptions] = useState({
        plugins: [dayGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        initialDate: getInitialDate(),
        headerToolbar: {
            left: 'title',
            center: '',
            right: 'prev,next',
        },
        firstDay: 1,
        eventColor: 'transparent',
        eventTextColor: '#333',
        eventBorderColor: 'transparent',
        displayEventTime: true,
        eventClick: eventClick,
        eventContent: function (arg: any) {
            return (
                <div className="event__wrap">
                    {/* {arg.event.extendedProps?.image_url && <img src={arg.event.extendedProps?.image_url} alt={`${arg.event.title} icon`} className={`event__icon event__icon__${arg.event.title}`} />} */}
                    <h3 className="event__title">{arg.event.title}</h3>
                    {arg.event.extendedProps.time && <p className="event__time">{arg.event.extendedProps.time}</p>}
                    {arg.event.extendedProps.event === 'Crayfish Party' && <span className="event__special">🦞 Crayfish Party</span>}
                    {arg.event.extendedProps.link && (
                        <button
                            className="book-table-button btn btn-primary"
                            onClick={() => window.open(arg.event.extendedProps.link, '_blank')}
                        >
                            Boka Julbord
                        </button>
                    )}
                </div>
            );
        },
        aspectRatio: 1.5,
        height: 'auto',
    });

    useEffect(() => {
        function updateCalendarSettings() {
            const isMobile = window.innerWidth <= 1024;
            if (calendarRef.current) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.changeView(isMobile ? 'listWeek' : 'dayGridMonth');
                calendarApi.setOption('aspectRatio', isMobile ? 0.75 : 1.5);
            }
            console.log('Calendar settings updated');
            
        }

        updateCalendarSettings();
        window.addEventListener('resize', updateCalendarSettings);

        return () => {
            window.removeEventListener('resize', updateCalendarSettings);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Calendar - Hallwylska Restaurang</title>
                <meta name="description" content="Stay up to date with the latest events at Hallwylska Restaurang. View our upcoming and past events." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/calendar" />
                <meta property="og:title" content="Calendar - Hallwylska Restaurang" />
                <meta property="og:description" content="Stay up to date with the latest events at Hallwylska Restaurang. View our upcoming and past events." />
                <meta property="og:image" content="https://hallwylskarestaurang.com/img/about.webp" />
                <meta property="og:url" content="https://hallwylskarestaurang.com/calendar" />
                <meta name="twitter:title" content="Calendar - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Stay up to date with the latest events at Hallwylska Restaurang. View our upcoming and past events." />
                <meta name="twitter:image" content="https://hallwylskarestaurang.com/img/about.webp" />
            </Helmet>
            <div className="calendar-wrap" id="calendar">
                <div className="container">
                    <h1 className="title text-center" style={{marginBottom: 0}}>Calendar</h1>
                    <p className="mt-3">Priser för Julbord beror på sittningstid. Se <Link to="/julbord">julbordsfliken</Link> för uppdaterad information.</p>
                    <Button 
                        class_type="primary" 
                        text="Julbordsinformation" 
                        onClick={() => navigate('/julbord')} 
                    />
                    <br /><br />
                    <FullCalendar {...calendarOptions} events={[...events, ...julbordEvents]} ref={calendarRef} />
                </div>
            </div>
        </>
    );
}

function generateJulbordEvents(startDate: string, endDate: string) {
    const events = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const dateStr = d.toISOString().split('T')[0];

        // Special times for 11th-14th and 18th-21st December
        let time = '11.30, 15.00, 19.30';
        if (d >= new Date('2024-12-11') && d <= new Date('2024-12-14')) {
            time = '11.00, 14.00, 17.00, 20.00';
        } else if (d >= new Date('2024-12-18') && d <= new Date('2024-12-21')) {
            time = '11.00, 14.00, 17.00, 20.00';
        }

        events.push({
            title: 'Julbord',
            date: dateStr,
            time: time,
            link: 'https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0',
        });
    }

    return events;
}

function getInitialDate() {
    const currentYear = new Date().getFullYear();
    const november = new Date(`${currentYear}-11-01`);
    const today = new Date();

    // Compare today's date with November 1st of the current year
    if (today > november) {
        return today.toISOString().split('T')[0]; // Return today's date if it's after November
    } else {
        return november.toISOString().split('T')[0]; // Otherwise, return November 1st
    }
}