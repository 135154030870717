import React from "react";

import './Menu.css';
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";

export const Menu: React.FC = (): JSX.Element => {

    return (
        <>
            <Helmet>
                <title>Menu - Hallwylska Restaurang</title>
                <meta name="description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/menu" />
                <meta property="og:title" content="Menu - Hallwylska Restaurang" />
                <meta property="og:description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <meta property="og:image" content="https://hallwylskarestaurang.com/img/menu/julbord.png" />
                <meta property="og:url" content="https://hallwylskarestaurang.com/menu" />
                <meta name="twitter:title" content="Menu - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <meta name="twitter:image" content="https://hallwylskarestaurang.com/img/menu/julbord.png" />

            </Helmet>
            <section className="menu container pt-5">
                {/* <img src="/img/menu/alacarte1.png" 
                     alt="A la carte at hallwylska"/>
                <img src="/img/menu/alacarte2.png" 
                     alt="A la carte at hallwylska"/> */}
                <img src="/img/menu/julbord.png" alt="Hallwylska's julbord" className="mt-5 w-100" />
                <p className="text-center mt-5">
                    <Button text='Make a Reservation'
                        class_type="primary"
                        onClick={() => window.open('https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0')} />
                </p>
            </section>
        </>
    )
}