import React from "react";

import "./Button.css";

export type ButtonTypes = "button" | "submit" | "reset" | undefined;
export type ButtonClassTypes = 'primary' | 'light' | 'danger';

interface ButtonProps {
    class_type: ButtonClassTypes;
    type?: ButtonTypes;
    onClick?: () => void;
    text: string;
    icon?: string;
}

export default function Button({ class_type, type, onClick, text, icon }: ButtonProps): JSX.Element {
    if (!type)
        type = 'button';
    return (
        <button className={`default-button text-default box-3d btn btn-${class_type}`}
                type={type}
                onClick={onClick}>
                    { icon && 
                        <img src={`/img/icons/${icon}.png`}
                             height='15'
                             alt={`${icon} icon`}
                             className="button-icon"/>
                    }   
                    { text }
        </button>
    )
}